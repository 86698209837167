<template>
  <div>
    <div class="filter-container">
      <div class="uk-form-horizontal">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-first-column">
            <label
              class="uk-form-label filter-margin"
              for="form-horizontal-text"
            >Cari</label>
            <div class="uk-form-controls">
              <input
                id="form-horizontal-text"
                v-model="metaFilter.name"
                class="uk-input"
                type="text"
                placeholder="Cari judul / user..."
              >
            </div>
          </div>
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <label
              class="uk-form-label filter-margin"
              for="form-horizontal-text"
            >Tanggal Diubah</label>
            <div class="uk-form-controls">
              <div
                uk-grid
                class="uk-grid-small"
              >
                <div class="uk-width-2-5@s">
                  <datepicker
                    ref="updatedStart"
                    v-model="metaFilter.updated_start"
                    input-class="uk-input"
                    placeholder="DD-MM-YYYY"
                  />  
                </div>
                <div class="uk-width-1-5@s uk-text-center filter-padding">
                  to
                </div>
                <div class="uk-width-2-5@s">
                  <datepicker
                    ref="updatedEnd"
                    v-model="metaFilter.updated_end"
                    input-class="uk-input"
                    placeholder="DD-MM-YYYY"
                  />  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small filter-margin"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l first-column">
            <label
              class="uk-form-label filter-margin"
              for="sop_category"
            >
              Kategori
            </label>
            <div class="uk-form-controls">
              <multiselect
                id="sop_category"
                v-model="categoryChoose"
                v-validate="'required'"
                name="sop_category"
                label="name"
                track-by="name"
                :options="dataCategorySops"
                placeholder="Semua"
                open-direction="bottom"
                :searchable="true"
                :close-on-select="true"
                :max-heigh="500"
                :show-no-result="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan</span>
              </multiselect>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small filter-margin"
            uk-grid
          >
            <div class="uk-width-1-1 uk-text-right">
              <button
                class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
                @click="clearFilter()"
              >
                <span
                  uk-icon="icon: close; ratio: 0.9"
                  class="uk-margin-small-right"
                />Clear
              </button>
              <button
                class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
                @click="handleFind()"
              >
                <span
                  uk-icon="icon: search; ratio: 0.9"
                  class="uk-margin-small-right"
                />Cari
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="uk-child-width-expand@s uk-grid-small uk-margin"
      uk-grid
    >
      <div class="uk-width-1-1@s uk-text-left">
        <button
          v-if="isCanAccessUser(`add`, `Detail SOP`)"
          class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
          @click="toImport()"
        >
          <span
            uk-icon="icon: upload; ratio: 0.7"
            class="uk-margin-small-right"
          />Import
        </button>
        <button
          v-if="isCanAccessUser(`add`, `Detail SOP`)"
          class="uk-button uk-button-default uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
          @click="toAdd()"
        >
          <span
            uk-icon="icon: plus; ratio: 0.7"
            class="uk-margin-small-right"
          />Tambah Detail SOP
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import { dateString } from '@/utils/formater'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      categoryChoose: '',
      metaFilter: {
        name: '',
        sop_category: ''
      },
      dataCategorySops:[]
    }
  },
  computed : {
    ...mapGetters({
      categorySops: 'categorySop/categorySops',
      meta: 'detailSop/meta',
      metaCategorySop: 'categorySop/meta'
    })
  },
  watch: {
    'metaFilter.updated_start'() {
      this.metaFilter.updated_start = this.dateFormatFilter(this.metaFilter.updated_start)
    },
    'metaFilter.updated_end'() {
      this.metaFilter.updated_end = this.dateFormatFilter(this.metaFilter.updated_end)
    }
  },
  async mounted() {
    await this.getCategorySop({is_all: true})
    this.dataCategorySops = this.categorySops ? this.categorySops : []
  },
  methods: {
    dateFormatFilter(date) {
      return dateString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapActions({
      getCategorySop: 'categorySop/getCategorySop'
    }),
    ...mapMutations({
      setMeta: 'detailSop/SET_META'
    }),
    handleFind () {
      this.metaFilter.page = 1
      this.metaFilter.sop_category = this.categoryChoose ? this.categoryChoose.name ? this.categoryChoose.name : '' : ''
      this.setMeta({
        ...this.meta,
        ...this.metaFilter
      })
    },
    async clearFilter() {
      this.$refs.updatedStart.clearDate()
      this.$refs.updatedEnd.clearDate()
      this.metaFilter.name = ''
      this.metaFilter.sop_category = ''
      this.categoryChoose =''
      await this.handleFind()
    },
    toImport() {
      this.$router.push(`/admin/detail-sop/import`)
    },
    toAdd() {
      this.$router.push(`/admin/detail-sop/add`)
    }
  }
}
</script>
