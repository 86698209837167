<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table class="uk-table uk-table-striped uk-table-hover uk-table-divider">
        <thead class="thead-table-paranje">
          <tr>
            <th class="uk-width-small">
              <span class="uk-text-bold">Nama Kategori</span>
            </th>
            <th class="uk-width-small">
              <span class="uk-text-bold">Judul</span>
            </th>
            <th class="uk-width-small">
              <span class="uk-text-bold">Terakhir Diubah</span>
            </th>
            <th class="uk-width-small">
              <span class="uk-text-bold">Diubah Oleh</span>
            </th>
            <th class="uk-table-expand uk-text-center">
              <span class="uk-text-bold">Aksi</span>
            </th>
          </tr>
        </thead>
        <template v-if="!is_loading">
          <tbody v-if="detailSops.meta.total_count>0">
            <tr
              v-for="(sop, i) in detailSops.data"
              :key="i"
            >
              <td>{{ sop.sop_category ? sop.sop_category : '-' }}</td>
              <td>{{ sop.name ? sop.name : '-' }}</td>
              <td>{{ formatDate(sop.updated_at) }}</td>
              <td>{{ sop.updated_by ? sop.updated_by : '-' }}</td>
              <td class="uk-flex uk-flex-center">
                <img
                  v-if="isCanAccessUser(`delete`, `Detail SOP`)"
                  v-lazy="`${images}/icon/trash.svg`"
                  alt=""
                  class="uk-margin-small-right img-click"
                  @click="showDeleteConfirmModal(sop)"
                > 
                <img
                  v-lazy="`${images}/icon/edit-black.svg`"
                  alt=""
                  class="img-click"
                  @click="goToEdit(sop.id)"
                >
                <img
                  v-if="isCanAccessUser(`edit`, `Detail SOP`)"
                  v-lazy="`${images}/icon/eye.svg`"
                  alt=""
                  class="uk-margin-small-left img-click"
                  @click="showDetail(sop.id)"
                >
              </td>
            </tr>
          </tbody>
          <empty-table
            v-else
            :colspan="5"
          />
        </template>
        <template v-else>
          <loading-table :colspan="5" />
        </template>
      </table>
    </div>
    <pagination
      :total-data="detailSops.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="meta.page"
      :current-limit="meta.limit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Pagination from '@/components/globals/Pagination'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import { STATUS } from '@/utils/constant'
import { dateUtcParanjeString } from '@/utils/formater'
import { PREFIX_IMAGE } from '@/utils/constant'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination
  },
  data() {
    return {
      is_loading: true,
      status: STATUS,
      images: PREFIX_IMAGE
    }
  },
  computed : {
    ...mapGetters({
      detailSops: 'detailSop/detailSops',
      meta: 'detailSop/meta'
    })
  },
  watch: {
    async meta() {
      this.is_loading = true
      await this.getDetailSop(this.meta)
      this.is_loading = false
    }
  },
  async mounted() {
    await this.getDetailSop(this.meta)
    this.is_loading = false
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapActions({
      getDetailSop: 'detailSop/getDetailSop'
    }),
    ...mapMutations({
      setMeta: 'detailSop/SET_META',
      setModalDelete: 'detailSop/SET_MODAL_DELETE'
    }),
    changeLimit(e) {
      this.setMeta({
        ...this.meta,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMeta({
        ...this.meta,
        page: value
      })
    },
    showDetail (id) {
      this.$router.push(`/admin/detail-sop/detail/${id}`)
    },
    goToEdit (id) {
      this.$router.push(`/admin/detail-sop/edit/${id}`)
    },
    showDeleteConfirmModal (detailSop) {
      window.UIkit.modal('#modal-delete-confirm').show()
      this.setModalDelete(detailSop)
    }
  }
}
</script>
